import React, { useEffect, useContext } from 'react';
import axios from 'axios';

import { adalToken } from './adalConfig';
import { GlobalProvider, GlobalCtx } from './components/AppContext/GlobalStore';
import { DashboardProvider } from './components/AppContext/DashboardStore';
import { ApiServiceGet } from './components/ApiService/';
import LoadAnim from './components/LoadAnim/LoadAnim';
import AlertProvider from './components/AlertProvider/AlertProvider';
// import createWrkCtrMembersArr from './components/Helpers/createWrkCtrMembersArr';
import loadWorkcenterInfo from './components/Helpers/loadWorkcenterInfo';
import logout from './components/Helpers/logout';

import { FEModule } from './components/Helpers/enums';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
    faBackspace,
    faTimes,
    faWindowClose,
    faBars,
    faFolder,
    faMinus,
    faEllipsisH,
    faCalculator,
    faPlusSquare,
    faMinusSquare,
} from '@fortawesome/free-solid-svg-icons';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import CheckVersion from './components/Versioning/CheckVersion';
// import { AppInsightsContext } from '@microsoft/applicationinsights-react-js';
// import { reactPlugin } from './ApplicationInsights';

const Dashboard = React.lazy(() => import('./components/Dashboard/Dashboard'));
const RoleSelect = React.lazy(() => import('./components/RoleSelect/RoleSelect'));
const WmsRouting = React.lazy(() => import('./components/WMS/wmsRouting'));

library.add(
    faTimes,
    faBackspace,
    faWindowClose,
    faBars,
    faFolder,
    faMinus,
    faCalculator,
    faEllipsisH,
    faPlusSquare,
    faMinusSquare
);

const TheApp = () => {
    const { state, actions } = useContext(GlobalCtx);

    useEffect(() => {
        const initializeUserData = async () => {
            try {
                if (state.user.authenticated === false) {
                    actions.setUserData();
                    return;
                }

                //company data
                if (state.currentCompany.companyId == null) {
                    const userCompany = { company: state.user.companyId };

                    const companyTing = await Promise.all([
                        ApiServiceGet.loadCompanyData(userCompany),
                        ApiServiceGet.companyEmployees(userCompany),
                        ApiServiceGet.workCenters(userCompany),
                        ApiServiceGet.stopReasons(userCompany),
                        ApiServiceGet.loadCompanyParameters(userCompany),
                    ]);
                    actions.setCompanyData({
                        company: { ...companyTing[0].data, params: companyTing[4].data[0] },
                        users: companyTing[1].data,
                        wrkCtrs: companyTing[2].data,
                        stopReasons: companyTing[3].data,
                    });
                    return;
                }

                // user has more than one role
                if (state.user.userRole === '' && state.user.roleModules.length > 1) {
                    //  user has multiple roles
                    actions.viewRoleSelect();
                    return;
                }

                // when workcenter is changed -->
                const localWrkCtrId =
                    state.currentWrkCtr.id !== ''
                        ? state.currentWrkCtr.id
                        : state.user.defaultWorkplace;
                // when workcenter is changed <--
                loadCurrentWrkCtrInfo(localWrkCtrId);
                // loadWrkCtrs(wrkctrinfo);
            } catch (e) {
                //logout();
            }
        };

        if (state.userDataLoaded === false) initializeUserData();
        /* eslint-disable */
    }, [
        state.user.authenticated,
        state.userDataLoaded,
        state.viewRoleSelect,
        state.companyUsers.length,
        state.currentCompany
    ]);
    /* eslint-enable */

    const loadCurrentWrkCtrInfo = async wrkCtr => {
        const infoObj = await loadWorkcenterInfo({
            state,
            wrkCtr,
            userRole: state.user.defaultRole,
            companyUsers: state.companyUsers,
        });
        actions.finishInitalDataLoad({ currentWrkCtrInfo: infoObj });
    };

    const Component = () => {
        useEffect(() => {
            axios.interceptors.request.use(
                async request => {
                    await adalToken(request);
                    return request;
                },
                error => {
                    return Promise.reject(error);
                }
            );
            axios.interceptors.response.use(
                response => {
                    return response;
                },
                async error => {
                    if (error?.response?.status === 401) {
                        //logout();
                    }
                    return Promise.reject(error);
                }
            );
        }, []);
        if (state.viewRoleSelect === true) return <RoleSelect state={state} />;
        if (state.userDataLoaded === true) {
            switch (state.user.userRole) {
                case FEModule.MES.id:
                case FEModule.QMS.id:
                    return (
                        <>
                            <DashboardProvider>
                                <Dashboard />
                            </DashboardProvider>
                        </>
                    );
                case FEModule.WMS.id:
                    return (
                        <>
                            <DashboardProvider>
                                <WmsRouting />
                            </DashboardProvider>
                        </>
                    );
                default:
                    return (
                        <>
                            <DashboardProvider>
                                <Dashboard />
                            </DashboardProvider>
                        </>
                    );
            }
        }

        return <LoadAnim animtext='Valmistame andmeid ette' />;
    };

    return (
        <>
            <AlertProvider>
                <Component />
            </AlertProvider>
        </>
    );
};

const App = () => {
    return (
        <React.Suspense fallback={<LoadAnim animtext='Valmistame andmeid ette' />}>
            <CheckVersion />
            <GlobalProvider>
                <TheApp />
            </GlobalProvider>
        </React.Suspense>
    );
};

export default App;
