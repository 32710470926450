import ApiMethods from './ApiServiceGet';

const ApiServiceGet = props => {
    const state = props.state;
    const action = props.action;
    const reqProps = props.props || {};
    const defaultData = {
        companyId: state.currentCompany.companyId,
        workCenterId: state.currentWrkCtr.id,
        defaultRole: state.user.defaultRole,
        userId: state.user.id,
    };
    if (!Object.keys(ApiServiceGetActions).includes(action)) return '';
    // const uri = ApiMethods[action]({ ...reqProps, defaultData });
    // return uri;
    const propsObj = { ...reqProps, defaultData };

    switch (action) {
        case ApiServiceGetActions.newWorkItemsURI:
            return ApiMethods.newWorkItemsURI(propsObj);
        case ApiServiceGetActions.getLastWorkedHoursURI:
            return ApiMethods.getLastWorkedHoursURI(propsObj);
        case ApiServiceGetActions.allActiveWorkItemsURI:
            return ApiMethods.allActiveWorkItemsURI(propsObj);
        case ApiServiceGetActions.teamworkActiveWorkItemsURI:
            return ApiMethods.teamworkActiveWorkItemsURI(propsObj);
        case ApiServiceGetActions.getTransactionLogURI:
            return ApiMethods.getTransactionLogURI(propsObj);
        case ApiServiceGetActions.getTransactionLogMesURI:
            return ApiMethods.getTransactionLogMesURI(propsObj);
        case ApiServiceGetActions.getWorkTime:
            return ApiMethods.getWorkTime(propsObj);
        case ApiServiceGetActions.loadPinUserData:
            return ApiMethods.loadPinUserData(propsObj);
        case ApiServiceGetActions.getWorkcenterInfo:
            return ApiMethods.getWorkcenterInfo(propsObj);
        case ApiServiceGetActions.getExtUserStatus:
            return ApiMethods.getExtUserStatus(propsObj);
        case ApiServiceGetActions.loadIdUserData:
            return ApiMethods.loadIdUserData(propsObj);
        case ApiServiceGetActions.getBomList:
            return ApiMethods.getBomList(propsObj);
        case ApiServiceGetActions.getLabelInfo:
            return ApiMethods.getLabelInfo(propsObj);
        case ApiServiceGetActions.getMatFeedbackFormSetup:
            return ApiMethods.getMatFeedbackFormSetup(propsObj);
        case ApiServiceGetActions.getWorkcenterPlannedTime:
            return ApiMethods.getWorkcenterPlannedTime(propsObj);
        case ApiServiceGetActions.coProductsQtyFormSetupUri:
            return ApiMethods.coProductsQtyFormSetupUri(propsObj);
        case ApiServiceGetActions.default:
            return;
    }
};
export const ApiServiceGetActions = {
    newWorkItemsURI: 'newWorkItemsURI',
    getLastWorkedHoursURI: 'getLastWorkedHoursURI',
    allActiveWorkItemsURI: 'allActiveWorkItemsURI',
    teamworkActiveWorkItemsURI: 'teamworkActiveWorkItemsURI',
    getTransactionLogURI: 'getTransactionLogURI',
    getTransactionLogMesURI: 'getTransactionLogMesURI',
    getWorkTime: 'getWorkTime',
    loadPinUserData: 'loadPinUserData',
    getWorkcenterInfo: 'getWorkcenterInfo',
    loadIdUserData: 'loadIdUserData',
    getBomList: 'getBomList',
    getLabelInfo: 'getLabelInfo',
    getMatFeedbackFormSetup: 'getMatFeedbackFormSetup',
    getWorkcenterPlannedTime: 'getWorkcenterPlannedTime',
    coProductsQtyFormSetupUri: 'coProductsQtyFormSetupUri',
};
export default ApiServiceGet;
